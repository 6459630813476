import { Protocol } from './index';
import { Category } from '../constants';

const infra: Protocol[] = [
  {
    name: '0xsplits',
    url: 'https://www.0xsplits.xyz/',
    logoPath: '/logos/0xsplits.jpg',
    category: Category.Infra,
    twitterUrl: 'https://twitter.com/0xsplits',
  },
  {
    name: 'Amberdata',
    url: 'https://amberdata.io/',
    logoPath: '/logos/amberdata.jpg',
    category: Category.Infra,
    twitterUrl: 'https://twitter.com/Amberdataio',
  },
  {
    name: 'Biconomy',
    url: 'https://www.biconomy.io/',
    logoPath: '/logos/biconomy.jpg',
    category: Category.Infra,
    twitterUrl: 'https://twitter.com/biconomy',
  },
  {
    name: 'Blockdaemon',
    url: 'https://blockdaemon.com/',
    logoPath: '/logos/blockdaemon.jpg',
    category: Category.Infra,
    twitterUrl: 'https://twitter.com/BlockdaemonHQ',
  },
  {
    name: 'BlockJoy',
    url: 'https://blockjoy.com/',
    logoPath: '/logos/blockjoy.jpg',
    category: Category.Infra,
    twitterUrl: 'https://twitter.com/BlockJoyWeb3',
  },
  {
    name: 'Blocknative',
    url: 'https://onboard.blocknative.com/',
    logoPath: '/logos/blocknative.jpg',
    category: Category.Infra,
    twitterUrl: 'https://twitter.com/blocknative',
  },
  {
    name: 'Covalent',
    url: 'https://www.covalenthq.com/',
    logoPath: '/logos/covalent.jpg',
    category: Category.Infra,
    twitterUrl: 'https://twitter.com/Covalent_HQ',
  },
  {
    name: 'Debank',
    url: 'https://debank.com/',
    logoPath: '/logos/debank.jpg',
    category: Category.Infra,
    twitterUrl: 'https://twitter.com/DebankDeFi',
  },
  {
    name: 'Defillama',
    url: 'https://defillama.com/',
    logoPath: '/logos/defillama.jpg',
    category: Category.Infra,
    twitterUrl: 'https://twitter.com/DefiLlama',
  },
  {
    name: 'Flipside',
    url: 'https://flipsidecrypto.xyz/',
    logoPath: '/logos/flipside.jpg',
    category: Category.Infra,
    twitterUrl: 'https://twitter.com/flipsidecrypto',
  },
  {
    name: 'GhostGraph',
    url: 'https://ghostgraph.xyz',
    logoPath: '/logos/ghostlogs.xyz.jpg',
    category: Category.Infra,
    twitterUrl: 'https://twitter.com/0xGhostLogs',
  },
  {
    name: 'GhostLogs',
    url: 'https://ghostlogs.xyz',
    logoPath: '/logos/ghostlogs.xyz.jpg',
    category: Category.Infra,
    twitterUrl: 'https://twitter.com/0xGhostLogs',
  },
  {
    name: 'Goldsky',
    url: 'https://goldsky.com/',
    logoPath: '/logos/goldsky.jpg',
    category: Category.Infra,
    twitterUrl: 'https://twitter.com/goldskyio',
  },
  {
    name: 'Integral',
    url: 'https://integral.xyz/',
    logoPath: '/logos/integral.jpg',
    category: Category.Infra,
    twitterUrl: 'https://twitter.com/integral_xyz_',
  },
  {
    name: 'Moralis',
    url: 'https://moralis.io/',
    logoPath: '/logos/moralis.jpg',
    category: Category.Infra,
    twitterUrl: 'https://twitter.com/MoralisWeb3',
  },
  {
    name: 'Nansen',
    url: 'https://www.nansen.ai/',
    logoPath: '/logos/nansen.jpg',
    category: Category.Infra,
    twitterUrl: 'https://twitter.com/nansen_ai',
  },
  {
    name: 'Nexandria',
    url: 'https://www.nexandria.com/',
    logoPath: '/logos/nexandria-blue.jpg',
    category: Category.Infra,
    twitterUrl: 'https://twitter.com/nexandria_co',
  },
  {
    name: 'Nodereal',
    url: 'https://nodereal.io/',
    logoPath: '/logos/nodereal.jpg',
    category: Category.Infra,
    twitterUrl: 'https://twitter.com/Nodereal_io',
  },
  {
    name: 'Quicknode',
    url: 'https://www.quicknode.com/',
    logoPath: '/logos/quicknode.jpg',
    category: Category.Infra,
    twitterUrl: 'https://twitter.com/quicknode',
  },
  {
    name: 'Tenderly',
    url: 'https://tenderly.co/',
    logoPath: '/logos/tenderly.jpg',
    category: Category.Infra,
    twitterUrl: 'https://twitter.com/TenderlyApp',
  },
  {
    name: 'The Graph',
    url: 'https://thegraph.com/',
    logoPath: '/logos/the_graph.jpg',
    category: Category.Infra,
    twitterUrl: 'https://twitter.com/graphprotocol',
  },
  {
    name: 'Thirdweb',
    url: 'https://thirdweb.com/',
    logoPath: '/logos/thirdweb.jpg',
    category: Category.Infra,
    twitterUrl: 'https://twitter.com/thirdweb',
  },
  {
    name: 'Walletconnect',
    url: 'https://www.walletconnect.com/',
    logoPath: '/logos/walletconnect.jpg',
    category: Category.Infra,
    twitterUrl: 'https://twitter.com/walletconnect/',
  },
];

export { infra };
